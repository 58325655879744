<script setup lang="ts">
const { t } = useI18n();
const localePath = useLocalePath();
useHead({
  title: t("p-index-head-title"),
  meta: [
    {
      name: "description",
      content: t("p-index-head-description"),
    },
    {
      name: "keywords",
      content: t("p-index-head-keywords"),
    },
  ],
});
import { Picture } from "@element-plus/icons-vue";
const { data: userData } = useAuth();
const { width } = useWindowSize();
definePageMeta({ auth: false });
const showRegButtons = ref(false);
onMounted(async () => {
  if (!userData.value) {
    await nextTick();
    showRegButtons.value = true;
  }
});
const mobileSize = computed(() => width.value < 600);
</script>

<template>
  <div>
    <div class="main-img">
      <div
        class="default-width overflow-hidden mt-10 mb-15 main-page-p main-img-inner"
      >
        <el-row :gutter="50" class="welcome-row">
          <el-col :span="24" :md="12" class="welcome-col">
            <h1>{{ t("p-index-welcome-title") }}</h1>
            <ul>
              <li>{{ t("p-index-welcome-no-fees") }}</li>
              <li>{{ t("p-index-welcome-stable") }}</li>
              <li>{{ t("p-index-welcome-no-id") }}</li>
            </ul>
            <p>{{ t("p-index-welcome-message-2") }}</p>
            <ClientOnly>
              <Transition>
                <div v-if="showRegButtons">
                  <NuxtLink :to="localePath('/login')" class="mr-4">
                    <el-button
                      :size="mobileSize ? 'small' : 'large'"
                      type="primary"
                    >
                      {{ t("p-index-get-started") }}
                    </el-button>
                  </NuxtLink>
                  <NuxtLink :to="localePath('/about')">
                    <el-button :size="mobileSize ? 'small' : 'large'">
                      {{ t("p-index-learn-more") }}
                    </el-button>
                  </NuxtLink>
                </div>
              </Transition>
            </ClientOnly>
          </el-col>
          <el-col :span="24" :md="12">
            <h2 class="color-white">{{ t("p-index-prices-finder") }}</h2>
            <WidgetSearch />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="default-width mt-10 mb-10 main-page-p pl-2 pr-2">
        <HomeBanners />
      </div>
      <div class="default-width mt-10 mb-15 main-page-p pl-2 pr-2">
        <el-row :gutter="50">
          <el-col :span="24" :md="12" class="mb-5">
            <HomeHistoryWidgets />
          </el-col>
          <el-col :span="24" :md="12">
            <BlogWidget />
          </el-col>
        </el-row>
        <el-divider />
        <el-row class="mt-20" :gutter="50">
          <el-col :span="24" :md="13">
            <h2>{{ t("p-index-world-opening-title") }}</h2>
            <p>{{ t("p-index-world-opening-message-1") }}</p>
            <p>{{ t("p-index-world-opening-message-2") }}</p>
          </el-col>
          <el-col :span="11" class="hidden-sm-and-down">
            <el-image
              class="world-image rounded"
              src="/img/world.webp"
              fit="cover"
            >
              <template #error>
                <div class="image-slot">
                  <el-icon><Picture /></el-icon>
                </div>
              </template>
            </el-image>
          </el-col>
        </el-row>
      </div>
      <el-divider />
      <div class="default-width mt-10 mb-15 main-page-p text-center">
        <div>
          <h3>{{ t("p-index-mobile-app-title") }}</h3>
          <p>{{ t("p-index-mobile-app-message") }}</p>
          <div class="inline-block pl-2 pr-2">
            <div class="coming-soon">
              <div class="coming-soon-label">
                <div class="coming-soon-label-inner">
                  {{ t("p-index-coming-soon") }}
                </div>
              </div>
              <el-row :gutter="20" class="coming-soon-inner">
                <el-col :span="12" :xs="24">
                  <el-image
                    class="mobile-logo"
                    src="/img/apple-store.png"
                    fit="cover"
                  >
                  </el-image>
                </el-col>
                <el-col :span="12" :xs="24">
                  <el-image
                    class="mobile-logo"
                    src="/img/google-store.png"
                    fit="cover"
                  >
                  </el-image>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-5 default-width">
        <h4 class="mb-4 text-center">{{ t("p-index-subscribe-title") }}</h4>
        <SocialNetworks />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.coming-soon {
  position: relative;
  &-inner {
    opacity: 0.8;
  }
  &-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &-inner {
      padding: 0 0.5rem;
      border-radius: 100%;
      font-size: 2rem;
      background: var(--el-bg-color);
      box-shadow: 0 0 10px 10px var(--el-bg-color),
        -50px 0 50px 20px var(--el-bg-color),
        50px 0 50px 20px var(--el-bg-color);
    }
  }
}
.mobile-logo {
  opacity: 0.8;
  transition: 0.3s;
  width: 200px;
  &:hover {
    opacity: 1;
  }
}
.main-img {
  min-height: 200px;
  margin: -25px -20px;
  padding: 50px 0;
  position: relative;

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: url(/img/home-back.jpg) no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    box-shadow: inset 0 10px 20px -20px #0009, inset 0 -10px 20px -20px #0009;
    transition: 1s;

    @media (max-width: 592px) {
      display: none;
    }
  }

  &-inner {
    z-index: 2;
    position: relative;
    background: #000d;
    padding: 10px 50px 20px;
    border-radius: 5px;
    box-shadow: 5px 5px 20px #0008;
    width: calc(100% - 145px);

    @media (max-width: 592px) {
      width: auto;
      padding: 0;
      box-shadow: none;
      margin: 0.5rem 0 0;
    }
  }

  @media (max-width: 992px) {
    padding: 1px;
  }
  @media (max-width: 592px) {
    margin: 0;
  }
}
.main-page-p {
  font-size: 1.2rem;
  line-height: 1.5;
}
.main-icon {
  font-size: 50px;
  line-height: 50px;
  color: var(--el-text-color-placeholder);
  opacity: 0.66;
  &.success {
    color: var(--el-color-success);
  }
}

.world-image {
  height: 320px;
  width: 100%;
  box-shadow: 5px 5px 15px #0008;
  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--el-fill-color-light);
    color: var(--el-text-color-secondary);
    font-size: 30px;
  }
}
.image-slot .el-icon {
  font-size: 30px;
}
.welcome-row {
  flex-direction: row-reverse;
  @media (max-width: 592px) {
    padding: 0.5rem;
  }
}
.welcome-col {
  color: white;
  @media (max-width: 593px) {
    font-size: 80% !important;
    margin-bottom: 1rem;
    h1 {
      font-size: 100%;
    }
  }
}
</style>
